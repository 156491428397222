<template>
  <v-chip label class="text-center" x-small :color="color">
    {{ getSituacao(situacao) }}
  </v-chip>
</template>

<script>
export default {
  props: {
    situacao: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      color: "",
    };
  },
  methods: {
    getSituacao(situacao) {
      try {
        this.color = this.$constants.objetoSituacoesMatricula[situacao].color;
        return this.$constants.objetoSituacoesMatricula[situacao].name;
      } catch (error) {
        return "---";
      }
    },
  },
};
</script>
