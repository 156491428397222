<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'gestoesDeAulas',
                })
            "
            :disabled="submittingForm"
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          {{ editing ? "Editando" : "Criando" }} Plano Bimestral
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-if="gestaoDeAula">
        <v-card color="green lighten-1" outlined>
          <v-col cols="10">
            <v-card-text>
              <h4>Turma: {{ gestaoDeAula.turma?.descricao }}</h4>
              <h4>
                Disciplina:
                {{
                  gestaoDeAula.is_polivalencia === 1
                    ? gestaoDeAula.disciplina
                    : gestaoDeAula.disciplina.descricao
                }}
              </h4>
              <h4>
                Professor:
                {{ gestaoDeAula.professor ? gestaoDeAula.professor.nome : "[carregando...]" }}
              </h4>
              <v-alert
                v-if="bimestresBloqueados.length > 0"
                dense
                icon="mdi-lock"
                color="green lighten-3"
                class="mt-4"
              >
                <strong>Bimestres Bloqueados:</strong>
                <v-list color="green lighten-3" dense>
                  <v-list-item v-for="(bimestre, index) in bimestresBloqueados" :key="index">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip color="blue" text-color="white" class="ma-1">
                          {{
                            $constants.bimestres.find((b) => b.value === bimestre)?.desc ||
                            "Não há bloqueios"
                          }}
                        </v-chip>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-alert>
            </v-card-text>
          </v-col>
          <v-col cols="2">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-if="podeVerPlano"
                  x-small
                  class="ml-1 white--text"
                  color="blue"
                  v-bind="attrs"
                  v-on="on"
                  @click="() => verHistoricoPlanos()"
                >
                  <span>Histórico do lancamento de planos</span>
                </v-btn>
              </template>
            </v-tooltip>
          </v-col>
        </v-card>
      </v-col>
      <v-col cols="12 text-center" class="pt-2">
        <template>
          <v-toolbar>
            <v-tabs dark v-model="form.bimestre" background-color="primary" grow>
              <v-tab
                v-for="item of $constants.bimestres"
                :key="item.value"
                @click="() => atualizarPathAtual(item.value)"
                :disabled="bimestresBloqueados.includes(item.value)"
              >
                <v-badge v-if="form.bimestre === item.value" color="success">
                  {{ item.desc }}
                </v-badge>
                <div v-else>
                  {{ item.desc }}
                  <v-icon
                    v-if="bimestresBloqueados.includes(item.value)"
                    class="ml-2"
                    small
                    color="black"
                  >
                    mdi-lock
                  </v-icon>
                </div>
              </v-tab>
            </v-tabs>
          </v-toolbar>
        </template>
      </v-col>
    </v-row>
    <ValidationObserver v-slot="{ handleSubmit }" v-if="!loadingPlano">
      <form @submit.prevent="handleSubmit(salvarPlanos)" :disabled="submittingForm">
        <v-row>
          <v-col class="mb-6" cols="12">
            <e-label>Anexar plano bimestral</e-label>
            <div class="dropboxStyles">
              <v-row>
                <v-col cols="9">
                  <v-file-input
                    v-model="plano_bimestral[bimestreAtual]"
                    accept="application/pdf"
                    chips
                    dense
                    solo
                    label="Plano Bimestral"
                    prepend-icon="mdi-file"
                    chip-color="red"
                    @change="onFileSelected"
                  ></v-file-input>
                </v-col>
                <v-col cols="3" class="text-right" v-if="pathAtual">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        @click="downloadPlanoBimestral()"
                        class="ml-2"
                        color="red lighten-5"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon color="red lighten-1" small>fa fa-file-pdf</v-icon>
                        <span class="ml-2">Baixar plano</span>
                      </v-btn>
                    </template>
                    <span>Baixar plano</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="12" v-if="pathAtual">
                  <v-alert dense color="green" icon="mdi-check-circle" text border="left">
                    Enviado: {{ pathAtual }}
                  </v-alert>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Tematica" rules="required" v-slot="{ errors }">
              <e-label>UNIDADES TEMÁTICAS / PRÁTICAS DE LINGUAGEM</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].tematica"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>OBJETOS DO CONHECIMENTO</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].objeto_conhecimento"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Habilidades" rules="required" v-slot="{ errors }">
              <e-label>HABILIDADES</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].habilidades"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Metodologia" rules="required" v-slot="{ errors }">
              <e-label>METODOLOGIA</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].metodologia"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Recursos didaticos" rules="required" v-slot="{ errors }">
              <e-label>RECURSOS DIDÁTICOS</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].recursos_didaticos"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider
              name="Avaliação da aprendizagem"
              rules="required"
              v-slot="{ errors }"
            >
              <e-label>AVALIAÇÃO DA APRENDIZAGEM</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].avaliacao_aprendizagem"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
          <v-col cols="12">
            <ValidationProvider name="Referencia" rules="required" v-slot="{ errors }">
              <e-label>REFERÊNCIAS</e-label>
              <v-textarea
                solo
                dense
                v-model="formData[bimestreAtual].referencias"
                :error-messages="errors"
              />
            </ValidationProvider>
          </v-col>
        </v-row>
        <v-col cols="12">
          <v-row>
            <v-btn
              block
              color="primary"
              :disabled="submittingForm"
              :loading="submittingForm"
              type="submit"
              >Enviar Dados</v-btn
            >
          </v-row>
        </v-col>
      </form>
    </ValidationObserver>
    <v-row v-else>
      <v-col cols="12">
        <v-sheet :color="`grey ${theme.isDark ? 'darken-2' : 'lighten-4'}`" class="pa-3">
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            height="100"
            type="paragraph"
          ></v-skeleton-loader>
          <v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            height="100"
            type="paragraph"
          ></v-skeleton-loader
          ><v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            height="100"
            type="paragraph"
          ></v-skeleton-loader
          ><v-skeleton-loader
            class="mx-auto"
            max-width="100%"
            height="100"
            type="paragraph"
          ></v-skeleton-loader>
        </v-sheet>
      </v-col>
    </v-row>
    <e-drawer-planos-lancados
      v-model="openDrawer"
      :gestao_id="parseInt($route.params.gestaoDeAulaId, 10)"
      :bimestre="parseInt(form.bimestre, 10)"
      :disciplina_id="disciplina_id"
      @reloadPage="reloadPageData"
    />
  </main-template>
</template>

<script>
import { mapGetters } from "vuex";
import ELabel from "../../components/ELabel.vue";

export default {
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  components: { ELabel },
  props: {
    editing: {
      type: Boolean,
      default: false,
    },
    gestaoDeAulaId: {
      type: [String, Number],
      require: true,
    },
  },
  async mounted() {
    this.loadingPlano = true;
    await this.loadData().then(() => {
      this.loadingPlano = false;
    });
  },
  data() {
    return {
      submittingForm: false,
      form: {
        bimestre: 0,
      },
      openDrawer: false,
      gestaoDeAula: {
        turma: {
          descricao: "",
        },
        disciplina: {
          descricao: "",
        },
        disciplinas: [],
      },
      loadingPlano: true,
      bimestresBloqueados: [],
      plano_bimestral: [],
      path: null,
      pathsList: [],
      pathAtual: [],
      formData: [],
      selectedTab: 0,
    };
  },
  computed: {
    ...mapGetters("Auth", ["user"]),
    podeVerPlano() {
      return this.$constants.rolesParaGestores.find(
        (rpg) =>
          rpg.name ===
          this.user.roles.filter((role) => role.name === "super-admin").find((r) => true)?.name
      );
    },
    bimestreAtual() {
      return this.form.bimestre;
    },
    disciplina_id() {
      return parseInt(this.$route.params.disciplinaId, 10);
    },
    gestao_de_aula_id() {
      return parseInt(this.$route.params.gestaoDeAulaId, 10);
    },
  },
  methods: {
    async salvarPlanos() {
      this.$loaderService.open("Carregando dados...");
      try {
        this.formData = this.formData.map((item) => {
          return {
            ...item,
            instrutorDisciplinaTurma_id: this.gestao_de_aula_id,
            disciplina_id: this.disciplina_id,
          };
        });

        await this.$services.planosBimestraisService.salvarPlanos(
          this.formData,
          this.gestao_de_aula_id,
          this.disciplina_id
        );

        await this.loadData();
        this.$toast.success("Planos salvos com sucesso!");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    atualizarPathAtual(bimestre) {
      this.pathAtual = this.formData[bimestre].path;
    },
    async onFileSelected(file) {
      if (file) {
        const { gestaoDeAulaId, disciplinaId } = this.$route.params;

        try {
          const response = await this.$services.planosBimestraisService.planoBimestralPDF(
            gestaoDeAulaId,
            this.bimestreAtual,
            disciplinaId,
            file
          );
          this.pathAtual = response.path;
          this.formData[bimestre].path = response.path;
        } catch (error) {
        } finally {
          this.isUploading = false;
        }
        this.$router.go();
      }
    },
    async downloadPlanoBimestral() {
      const { gestaoDeAulaId, disciplinaId } = this.$route.params;
      try {
        await this.$services.planosBimestraisService.planoBimestralDownload(
          gestaoDeAulaId,
          this.bimestreAtual,
          disciplinaId
        );
      } catch (error) {
        // console.error("Erro ao baixar o arquivo:", error);
      }
    },
    async verHistoricoPlanos() {
      this.openDrawer = !this.openDrawer;
    },
    async createOrUpdate() {
      this.$loaderService.open("Carregando dados...");
      try {
        const { gestaoDeAulaId } = this.$route.params;
        const { bimestre } = this.form;
        this.form.bimestre = bimestre;
        this.form.instrutorDisciplinaTurma_id = gestaoDeAulaId;
        this.form.disciplina_id = this.disciplina_id;
        await this.$services.planosBimestraisService.createOrUpdate(this.form);
        this.$toast.success("Plano bimestral salvo com sucesso!");
      } catch (error) {
        this.$handleError(error);
      } finally {
        this.$loaderService.close();
      }
    },
    async loadData() {
      const { gestaoDeAulaId, disciplinaId } = this.$route.params;

      try {
        this.gestaoDeAula = await this.$services.gestoesDeAulasService.visualizar(gestaoDeAulaId);

        const response = await this.$services.planosBimestraisService.pegarPlanos(
          gestaoDeAulaId,
          disciplinaId
        );

        if (response !== "") {
          this.form = response;
          this.form = response.planos;
          // this.objeto_aprendizagem = [];
          this.bimestresBloqueados = response.bimestres_bloqueados || [];
          if (this.bimestresBloqueados.includes(this.bimestreAtual)) {
            const { bimestres } = this.$constants;
            for (let i = 0; i < bimestres.length; i += 1) {
              const nextBimestre = bimestres[i].value;
              if (!this.bimestresBloqueados.includes(nextBimestre)) {
                bimestre = nextBimestre;
                break;
              }
            }
            const response = await this.$services.planosBimestraisService.pegarPlanos(
              gestaoDeAulaId,
              disciplinaId
            );
            this.form = response.planos;
          }
        }
        const bimestresExistentes = [];
        response.planos.map((plano, index) => {
          this.formData[plano.bimestre] = { ...plano };
          bimestresExistentes.push(plano.bimestre);
        });

        const bimestresInexistenes = this.$constants.bimestres
          .filter((bimestre) => {
            return !bimestresExistentes.includes(bimestre.value);
          })
          .map((b) => b.value);

        bimestresInexistenes.map((bimestre) => {
          this.formData[bimestre] = {
            instrutorDisciplinaTurma_id: gestaoDeAulaId,
            disciplina_id: disciplinaId,
            bimestre,
          };
        });

        if (response.planos.length > 0) {
          this.pathsList = response.planos.map((i) => i.path);
          this.pathAtual = this.pathsList[this.bimestreAtual];
        }

        if (this.gestaoDeAula.disciplinas.length > 0) {
          const disciplina = this.gestaoDeAula.disciplinas.filter((i) => {
            return i.id === this.disciplina_id;
          });
          this.gestaoDeAula.disciplina = disciplina[0].descricao;
        }

        this.$emit("dialogChange", !this.dialog);
      } catch (error) {
        this.$handleError(error);
      }
    },
    async reloadPageData() {
      await this.loadData();
    },
  },
};
</script>

<style></style>
