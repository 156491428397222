<template>
  <main-template>
    <v-row>
      <v-col>
        <h1>
          <v-btn
            color="primary"
            x-small
            text
            @click="
              () =>
                $router.push({
                  name: 'auditoria',
                })
            "
          >
            <v-icon left> fa fa-angle-left </v-icon>
          </v-btn>
          Auditoria de Turmas
        </h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
          v-model="search"
          class="my-2"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          solo
          autofocus
          dense
          clearable
          single-line
          hide-details
        />
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="turmasList"
          :items-per-page="15"
          :hide-default-footer="true"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="turma.estaDeletado ? ['deleted'] : []"
                v-for="turma of items"
                :key="turma.id"
              >
                <td>{{ turma.id }}</td>
                <td>{{ turma.franquia?.descricao ?? "- - -" }}</td>
                <td>{{ turma.descricao }}</td>
                <td>
                  <v-chip
                    class="ma-2"
                    :color="turma.qtdMatriculas <= 0 ? 'red' : 'green'"
                    text-color="white"
                  >
                    {{ turma.qtdMatriculas }}
                  </v-chip>
                </td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verHistorico(turma)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Histórico de Alterações</span>
                  </v-tooltip>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarGestoes"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>

    <e-drawer-ver-auditoria
      v-model="exibir"
      :auditated_model="plano ?? {}"
      :auditated_descripition="$route.name"
    >
    </e-drawer-ver-auditoria>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Turma from "@/Models/Turma";

export default {
  data() {
    return {
      plano: null,
      exibir: false,
      dataTableFilter,
      search: null,
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      disciplinas: [],
      disciplina: "",
      bimestres: [],
      bimestre: "",
      turmas: [],
      turma: "",
      professor: "",
      turmasList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Escola" },
          { text: "Turma" },
          { text: "Quantidade de Matrículas" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  watch: {
    gestoes(value) {
      if (value && this.turmasList.length === 0) {
        this.$loaderService.open("Carregando plano");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val?.length > 1) {
        await this.listarGestoes(0, val);
      }
      if (!val) {
        await this.listarGestoes(0);
      }
    },
  },
  async mounted() {
    this.gestoes = true;
    this.$loaderService.open("Carregando plano");
    await this.loadData();
    this.carregandoGestoes = false;
    this.$loaderService.close();
  },

  methods: {
    async loadData() {
      await this.listarGestoes(this.pageNumber);
    },
    async listarGestoes(pageNumber, query = null) {
      this.gestoes = true;
      try {
        const response = await this.$services.turmasService.auditoriaPaginate(pageNumber, query);
        this.turmasList = response.data.map((turma) => new Turma(turma));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.gestoes = false;
    },
    verHistorico(plano) {
      this.exibir = !this.exibir;
      this.plano = plano;
    },
  },
};
</script>

<style></style>
