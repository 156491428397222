import AuditoriaIndexPage from "@/Pages/Auditoria/index.vue";
import AuditoriaAulas from "@/Pages/Auditoria/AuditoriaAulas.vue";
import AuditoriaAlunos from "@/Pages/Auditoria/AuditoriaAlunos.vue";
import AuditoriaPlanosBimestrais from "@/Pages/Auditoria/AuditoriaPlanosBimestrais.vue";
import AuditoriaPlanosBimestraisInfantis from "@/Pages/Auditoria/AuditoriaPlanosBimestraisInfantis.vue";
import AuditoriaGestoesDeAulas from "@/Pages/Auditoria/AuditoriaGestoesDeAulas.vue";
import AuditoriaTurmas from "@/Pages/Auditoria/AuditoriaTurmas.vue";
import AuditoriaMatriculas from "@/Pages/Auditoria/AuditoriaMatriculas.vue";

export default [
  {
    path: "/auditoria/",
    name: "auditoria",
    component: AuditoriaIndexPage,
  },
  {
    path: "/auditoria/aulas",
    name: "auditoria.aulas",
    component: AuditoriaAulas,
  },
  {
    path: "/auditoria/alunos",
    name: "auditoria.alunos",
    component: AuditoriaAlunos,
  },
  {
    path: "/auditoria/planos-bimestrais",
    name: "auditoria.planos-bimestrais",
    component: AuditoriaPlanosBimestrais,
  },
  {
    path: "/auditoria/planos-bimestrais-infantis",
    name: "auditoria.planos-bimestrais-infantis",
    component: AuditoriaPlanosBimestraisInfantis,
  },
  {
    path: "/auditoria/gestoes-de-aulas",
    name: "auditoria.gestoes-de-aulas",
    component: AuditoriaGestoesDeAulas,
  },
  {
    path: "/auditoria/turmas",
    name: "auditoria.turmas",
    component: AuditoriaTurmas,
  },
  {
    path: "/auditoria/matriculas",
    name: "auditoria.matriculas",
    component: AuditoriaMatriculas,
  },
];
