<template>
  <main-template>
    <v-row>
      <v-row>
        <v-col>
          <h1>
            <v-btn
              color="primary"
              x-small
              text
              @click="
                () =>
                  $router.push({
                    name: 'auditoria',
                  })
              "
            >
              <v-icon left> fa fa-angle-left </v-icon>
            </v-btn>
            Auditoria de Alunos
          </h1>
        </v-col>
      </v-row>
    </v-row>
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-text-field
          v-model="search"
          class="my-2"
          append-icon="mdi-magnify"
          label="Pesquisar..."
          solo
          autofocus
          dense
          clearable
          single-line
          hide-details
        />
      </v-col>
      <v-col class="pt-0">
        <v-data-table
          :headers="table.headers"
          :items="alunosList"
          :items-per-page="15"
          :hide-default-footer="true"
          :custom-filter="dataTableFilter"
          class="elevation-1"
        >
          <template v-slot:body="{ items }">
            <tbody name="scroll-x-transition" is="transition-group" duration="150">
              <tr
                color="primary"
                :class="aluno.estaDeletado ? ['deleted'] : []"
                v-for="aluno of items"
                :key="aluno.id"
              >
                <td>{{ aluno.id }}</td>
                <td>
                  <strong>{{ aluno.codigo ? aluno.codigo : "- - -" }}</strong>
                </td>
                <td>{{ aluno.codigo_inep ? aluno.codigo_inep : "- - -" }}</td>
                <td>{{ aluno.nomecompleto }}</td>
                <td>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="blue-grey lighten-3"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => verHistorico(aluno)"
                      >
                        <v-icon small>fa fa-eye </v-icon>
                      </v-btn>
                    </template>
                    <span>Ver Histórico de Alterações</span>
                  </v-tooltip>
                  <!-- <v-tooltip v-if="aluno.estaDeletado" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        x-small
                        class="ml-1"
                        color="info"
                        v-bind="attrs"
                        v-on="on"
                        @click="() => recuperarAluno(aluno)"
                      >
                        <v-icon small>fa fa-sync </v-icon>
                      </v-btn>
                    </template>
                    <span>Recuperar aluno</span>
                  </v-tooltip> -->
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>

        <e-paginate
          :current_page="paginate.current_page"
          :last_page="paginate.last_page"
          :pageNumber="pageNumber"
          @changeActionResponse="listarAlunos"
          :search="search"
        ></e-paginate>
      </v-col>
    </v-row>

    <e-drawer-ver-auditoria
      v-model="exibir"
      :auditated_model="aluno ?? {}"
      :auditated_descripition="$route.name"
    >
    </e-drawer-ver-auditoria>
  </main-template>
</template>

<script>
import { dataTableFilter } from "@/plugins/searchQuery";
import Aluno from "@/Models/Aluno";

export default {
  computed: {
    //
  },
  data() {
    return {
      aluno: null,
      exibir: false,
      dataTableFilter,
      search: null,
      pageNumber: 0,
      paginate: {
        current_page: 0,
        last_page: 0,
      },
      alunosList: [],
      table: {
        headers: [
          { text: "#", value: "id" },
          { text: "Código de acesso", value: "codigo" },
          { text: "Identificação Única(INEP)", value: "codigo_inep" },
          { text: "Nome", value: "nomecompleto" },
          { text: "Ações", value: "actions", sortable: false },
        ],
      },
    };
  },
  async mounted() {
    this.alunos = true;
    this.$loaderService.open("Carregando alunos");
    await this.listarAlunos(this.pageNumber);
    this.carregandoGestoes = false;
    this.$loaderService.close();
  },
  watch: {
    alunos(value) {
      if (value && this.alunosList.length === 0) {
        this.$loaderService.open("Carregando alunos");
      } else {
        this.$loaderService.close();
      }
    },
    async search(val) {
      if (val?.length > 1) {
        await this.listarAlunos(0, val);
      }
      if (!val) {
        await this.listarAlunos(0);
      }
    },
  },
  methods: {
    async listarAlunos(pageNumber, query = null) {
      this.alunos = true;
      try {
        const response = await this.$services.alunosService.AuditoriaPaginate(pageNumber, query);
        this.alunosList = response.data.map((aluno) => new Aluno(aluno));
        this.paginate = response;
      } catch (error) {
        this.$handleError(error);
      }
      this.alunos = false;
    },
    verHistorico(aluno) {
      this.exibir = !this.exibir;
      this.aluno = aluno;
    },
    recuperarAluno(aluno) {
      this.$modals.warn({
        title: "Atenção!",
        message: "Tem certeza que deseja recuperar esta aluno?",
        confirmationCode: aluno.id,
        confirmationMessage: `Por favor, digite <strong>${aluno.id}</strong> para confirmar`,
        buttons: [
          {
            text: "Cancelar",
            callback: () => {
              this.$modals.close();
            },
            props: {
              color: "warning",
              small: true,
            },
          },
          {
            text: "Confirmar",
            disableIfCodeDoesntMatch: true,
            callback: async () => {
              this.$modals.close();
              this.$loaderService.open("Recuperando a Aluno");
              try {
                await this.$services.alunosService.recuperarAluno(aluno);
                this.$toast.success("Aluno recuperada com sucesso");
                this.$forceUpdate();
              } catch (error) {
                this.$handleError(error);
              }
              this.$loaderService.close();
            },
            props: {
              color: "error",
              // text: true,
              small: true,
            },
          },
        ],
      });
    },
  },
};
</script>

<style></style>
